import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostList from '../components/Blog/PostList';
import { Image } from '../components/Image'
import { PageHeader } from '../components/PageHeader';
import { decodeEntities } from '../utils/helpers';
import { Breadcrumbs } from '../components/Breadcrumbs';

export default class Category extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const { postsOnPage, categoryFilter, categories, site, wordpressWpSettings, siteSettings, thisCategoryPost } = data
    const { showAuthor } = siteSettings.options
    const { edges: posts, totalCount } = postsOnPage
    const { title: siteTitle, date_format } = wordpressWpSettings
    const { name: category, slug, pathPrefix } = pageContext
    const { description, acf, yoast } = thisCategoryPost
    const { title, subTitle } = acf
    const headerTitle = title ? title : category
    const featuredImage = acf && acf.featureImage && acf.featureImage.source_url ? acf.featureImage : ''

    return (
      <Layout className={`page-wrap`} location={location}>
        <SEO title={`${decodeEntities(category)} | ${decodeEntities(siteTitle)}`} yoast={yoast}
          location={location}
        />

        <section className="page-header category">
          <div className="page-slider">
            <div className="slide">
              <div className="title-block">
                <div className="wrap">
                  <div className="inner">
                    <Breadcrumbs location={location} />
                    {headerTitle && <h1 className="title" dangerouslySetInnerHTML={{__html: headerTitle}} />}
                    {subTitle && <span className="sub-title" dangerouslySetInnerHTML={{ __html: decodeEntities(subTitle) }} />}
                    {description && <span className="short-description" dangerouslySetInnerHTML={{ __html: decodeEntities(description) }} />}
                  </div>
                </div>
              </div>

              {featuredImage &&
                <div className={`background-wrap contain`}>
                  <div className="background"><div className="inner"><Image src={featuredImage} /></div></div>
                  {featuredImage.title && <div className="image-title" dangerouslySetInnerHTML={{ __html: decodeEntities(slide.background.title) }} />}
                </div>
              }
            </div>
          </div>
        </section>

        <PostList
          posts={posts}
          title={title}
          pageContext={pageContext}
          categories={categories.nodes}
          //categoryFilter={categoryFilter}
          siteMetadata={wordpressWpSettings}
          pathPrefix={pathPrefix}
          showAuthor={showAuthor}
          dateFormat={date_format}
        />
      </Layout>
    )
  }
}

Category.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query CategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
      date_format
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        showAuthor
      }
    },
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          path
          id
        }
      }
    }
    postsOnPage: allWordpressPost(
      filter: {
        categories: {elemMatch: {slug: {eq: $slug}}},
        slug: { ne: "dummy-post" }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    thisCategoryPost: wordpressCategory(slug: {eq: $slug}) {
      description
      slug
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
      acf {
        title
        subTitle
        featureImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 670, quality: 70) {
                ... GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      nodes {
        name
        slug
        path
        featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 670, quality: 70) {
                ... GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        count
      }
    }
  }
`
